<template>
  <div class="law" v-if="view">
    <div class="main" v-html="data"></div>
    <div class="black" @click="black">确认</div>
  </div>
</template>

<script>
import {privacy, protocol} from '../utils/law'

export default {
  data() {
    return {
      data: '',
      view: false,
    }
  },
  mounted() {

  },
  methods: {
    black() {
      this.view = false
    },
    init(str) {
      if (str == 'privacy') {
        let data = privacy()
        this.data = data
      } else if (str == 'protocol') {
        let data = protocol()
        this.data = data
      }
      this.view = true
    }
  },
}
</script>

<style scoped lang="scss">
.law {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: scroll;

  .header {
    width: 100%;
    height: 40px;
    line-height: 40px;

    .black {
      font-size: 14px;
      color: rgba(48, 157, 196, 0.81);
      text-indent: 1rem;
    }
  }

  .main {
    width: 90%;
    margin: 0 auto;
    font-size: 14px;
  }

  .black{
    width: 100%;
    height: 40px;
    background-color: #973733;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    margin: 0 auto;
    margin-top: 20px;
  }

}

</style>
